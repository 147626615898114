import type { ComponentType } from "react"
import React, { useEffect, useState } from "react"

export function withEmail(Component): ComponentType {
    return (props) => {
        const [email, setEmail] = useState("curso@framer.com")

        useEffect(() => {
            let localEmail
            if (typeof window !== "undefined") {
                localEmail = window.localStorage.getItem("emailForSignIn")
            }

            setEmail(localEmail ?? "curso@framer.com")
        }, [])

        return <Component {...props} text={email} />
    }
}
